import {
  add,
  subtract,
  distance2BetweenPoints,
  angleBetweenVectors,
  multiplyScalar,
  normalize,
  multiply3x3_vect3,
  radiansFromDegrees,
  dot,
  cross,
} from '@kitware/vtk.js/Common/Core/Math';

import type { Vector2 as vec2, Vector3 as vec3, Matrix3x3 as mat3 } from '@kitware/vtk.js/types';
import type { VtkDirection } from 'types/vtk';
import type { $AxisDirection } from '@kitware/vtk.js';
import vtkOpenGLRenderWindow from '@kitware/vtk.js/Rendering/OpenGL/RenderWindow';
import vtkRenderer from '@kitware/vtk.js/Rendering/Core/Renderer';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import type { Segment } from '../types';
import { CORRECTED_IMAGE_TAGS, IMAGE_NORMALS, IMAGE_VIEW_UPS, PET_UNITS } from 'config/constants';
import type { DreAllViewportTypes } from 'config/constants';
import type { Region, FrameDataFragment } from 'generated/graphql';
import type { ImageRegistration } from '../../ViewportsConfigurations/types';
import { getLPSDirections } from '../modules/imaging/utils';

type ScaleFactorTags = {
  readonly modules: {
    readonly petImage: FrameDataFragment['modules']['petImage'];
    readonly patientStudy: FrameDataFragment['modules']['patientStudy'];
  };
};

export function areViewportsParallel(
  direction1: VtkDirection,
  direction2: VtkDirection,
  normal1: vec3,
  normal2: vec3
): {
  parallel: boolean;
  normal1: vec3;
  normal2: vec3;
  angle: number;
} {
  const direction13x3Matrix = make3x3MatrixFromDirection(direction1);

  multiply3x3_vect3(direction13x3Matrix, normal1, normal1);
  const direction23x3Matrix = make3x3MatrixFromDirection(direction2);

  multiply3x3_vect3(direction23x3Matrix, normal2, normal2);

  const angle = angleBetweenVectors(normal1, normal2);
  const angleIsCloseTo360 = Math.abs(angle - Math.PI) < 0.01;
  const angleIsCloseTo0 = angle < 0.01;
  return { parallel: angleIsCloseTo0 || angleIsCloseTo360, normal1, normal2, angle };
}
export function areNormalsParallel(normal1: vec3, normal2: vec3): boolean {
  // radians
  const angle = angleBetweenVectors(normal1, normal2);
  // we allow a tight tolerance of 0.01 radians
  // a value of 3.14... === 360 degrees
  const angleIsCloseTo360 = Math.abs(angle - Math.PI) < 0.01;
  const angleIsCloseTo0 = angle < 0.01;
  return angleIsCloseTo0 || angleIsCloseTo360;
}
export function areNormalsParallelWithTolerance(
  normal1: vec3,
  normal2: vec3,
  tolerance: number
): boolean {
  const angle = angleBetweenVectors(normal1, normal2);
  // we allow a specified tolerance in our check
  // a value of 3.14... === 360 degrees
  const angleIsCloseTo360 = Math.abs(angle - Math.PI) < tolerance;
  const angleIsCloseTo0 = angle < tolerance;
  return angleIsCloseTo0 || angleIsCloseTo360;
}
export function make3x3MatrixFromDirection(direction: VtkDirection): mat3 {
  // vtk.js is still row-major, but just in a single array
  return [
    direction[0],
    direction[3],
    direction[6],
    direction[1],
    direction[4],
    direction[7],
    direction[2],
    direction[5],
    direction[8],
  ];
}

export const calculateAxis = (direction: VtkDirection): $AxisDirection => {
  const direction3x3Matrix = make3x3MatrixFromDirection(direction);
  const normal1: vec3 = [0, 0, -1];
  multiply3x3_vect3(direction3x3Matrix, normal1, normal1);
  const [dx, dy, dz] = normal1.map(Math.abs);
  if (dx > dz && dx > dy) {
    return 'X';
  }
  if (dy > dz && dy > dx) {
    return 'Y';
  }
  if (dx < dz && dz > dy) {
    return 'Z';
  }
  return 'X';
};

export const calculateMaxSlices = (
  image: vtkImageData | null | undefined,
  viewType: DreAllViewportTypes
): number => {
  const imageIndex = viewType === 'TWO_D_DRE' ? 2 : 1;
  return image ? image.getDimensions()[imageIndex] : 0;
};

const threshold = Math.sqrt(0.5);

function roundToUnitVector(
  inVec: [number, number, number],
  outVec: [number, number, number]
): void {
  for (let axis = 0; axis < inVec.length; ++axis) {
    const val = inVec[axis];
    outVec[axis] = val < -threshold ? -1 : val > threshold ? 1 : 0;
  }
}

export const calculateCameraParametersFromImageData = (
  viewType: DreAllViewportTypes,
  image: vtkImageData
): {
  viewUp: vec3;
  position: vec3;
  focalPoint: vec3;
  normal: vec3;
} => {
  const d9 = image.getDirection();
  let viewUp: vec3 = [...IMAGE_VIEW_UPS[viewType]];

  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'mat3' is not assignable to parameter of type 'VtkDirection'.
  const d3x3 = make3x3MatrixFromDirection(d9);
  const d3x3_inv = d9;
  const normal: vec3 = [...IMAGE_NORMALS[viewType]];
  let offset = normal;
  const lps = getLPSDirections(d9);

  if (viewType !== 'TWO_D_DRE') {
    // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'mat3' is not assignable to parameter of type 'Matrix3x3'.
    multiply3x3_vect3(d3x3_inv, normal, normal);
    // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'mat3' is not assignable to parameter of type 'Matrix3x3'.
    multiply3x3_vect3(d3x3_inv, viewUp, viewUp);
    roundToUnitVector(normal, normal);
    roundToUnitVector(viewUp, viewUp);
    if (viewType === 'THREE_D_MIP') {
      //positioning camera to show patient in an upright position for MIP volumes
      // @ts-expect-error [EN-7967] - TS2322 - Type 'Vec3[]' is not assignable to type 'Vector3'.
      viewUp = [...lps.Superior];
      // @ts-expect-error [EN-7967] - TS2322 - Type 'Vec3[]' is not assignable to type 'Vector3'.
      offset = [...lps.Anterior];
    }
  }

  multiply3x3_vect3(d3x3, normal, normal);
  multiply3x3_vect3(d3x3, viewUp, viewUp);

  /*
   * Camera positioning calculation
   *
   * We use parallel projection, so this is simple. We need to simply position the camera away from the image.
   *
   * The view frustrum is the 3D volume of space visible from the camera
   * It's a rectangle
   *
   * Here's how it works:
   *
   * 1. We have a sphere (bounding sphere) containing our 3D object
   * 2. We move the camera outside of the sphere (arbitrary -- it just needs to face the image)
   *
   */

  const focalPoint = image.getCenter();
  const bounds = image.getBounds();
  const dimensions = [
    bounds[1] - bounds[0], // x dimension
    bounds[3] - bounds[2], // y dimension
    bounds[5] - bounds[4], // z dimension
  ];
  // Use half the diagonal of the bounding box as the radius of the bounding sphere
  let radius =
    0.5 *
    Math.sqrt(
      dimensions[0] * dimensions[0] + dimensions[1] * dimensions[1] + dimensions[2] * dimensions[2]
    );

  // If we have just a single point, pick a radius of 1.0
  radius = radius === 0 ? 1.0 : radius;
  const distance = 2 * radius;

  // @ts-expect-error [EN-7967] - TS2322 - Type 'number[]' is not assignable to type 'Vector3'.
  const position: vec3 = focalPoint.map((e, i) => e - offset[i] * distance);

  return { viewUp, position, focalPoint, normal };
};

export const getMiddlePoint = (a: vec3, b: vec3): vec3 => {
  return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2, (a[2] + b[2]) / 2];
};

// Function to convert radians to degrees
export function radiansToDegrees(radians: number): number {
  return radians * (180 / Math.PI);
}

// Given a triangle abc, find the point 'd' on segment 'ac', such that:
// vector 'bd' bisects the angle formed at vertex b.
export function calculateAngleBisectingPoint(a: vec3, b: vec3, c: vec3): vec3 {
  const len_ba = Math.sqrt(distance2BetweenPoints(b, a));
  const len_bc = Math.sqrt(distance2BetweenPoints(b, c));
  const len_ac = Math.sqrt(distance2BetweenPoints(a, c));
  const len_ad = (len_ac * len_ba) / (len_ba + len_bc);
  const vec_ac = getVectorsDifference(c, a);
  normalize(vec_ac);
  const vec_ad = multiplyScalar(vec_ac, len_ad);
  const out_d: vec3 = [0, 0, 0];
  return add(vec_ad, a, out_d);
}

// Transform offset information (normalized direction in world coordinates and pixel length)
// into a vector in display coordinates.
export function worldToDisplayOffset(
  directionInWorld: vec3 | number[],
  distanceInPixels: number,
  openglRenderWindow: vtkOpenGLRenderWindow,
  renderer: vtkRenderer
): vec3 {
  const vecInPixels = getVectorsDifference(
    openglRenderWindow.worldToDisplay(
      directionInWorld[0],
      directionInWorld[1],
      directionInWorld[2],
      renderer
    ),
    openglRenderWindow.worldToDisplay(0, 0, 0, renderer)
  );
  normalize(vecInPixels);
  return multiplyScalar(vecInPixels, distanceInPixels);
}

// Function to find the angle in 3D space
export function findAngleBetweenThreePoints(a: vec3, b: vec3, c: vec3): number {
  const ab = Math.sqrt(distance2BetweenPoints(a, b));
  const bc = Math.sqrt(distance2BetweenPoints(b, c));
  const ac = Math.sqrt(distance2BetweenPoints(a, c));

  const angle = (Math.pow(ab, 2) + Math.pow(bc, 2) - Math.pow(ac, 2)) / (2 * ab * bc);
  // acos will end up NaN when greater than 1 or less than -1
  return radiansToDegrees(Math.acos(Math.min(Math.max(angle, -1), 1)));
}

export function distanceBetweenPoints(a: vec3, b: vec3): number {
  return Math.sqrt(distance2BetweenPoints(a, b));
}

export function getVectorsDifference(origin: vec3, destination: vec3): vec3 {
  return [origin[0] - destination[0], origin[1] - destination[1], origin[2] - destination[2]];
}

/*
 * Returns an angle difference between two vectors given vec1 as its origin
 * to know whether we are rotating clockwise or counter-clockwise
 *
 * Return value is in radians
 */
export function orientedAngleBetweenVectors(vec1: vec3, vec2: vec3, normal: vec3): number {
  return Math.atan2(dot(cross(vec2, vec1, [0, 0, 0]), normal), dot(vec1, vec2));
}

export function getCobbAngleMeasurement(
  start1: vec3,
  end1: vec3,
  start2: vec3,
  end2: vec3
): number {
  const vec1 = getVectorsDifference(start1, end1);
  const vec2 = getVectorsDifference(start2, end2);

  const angle = radiansToDegrees(angleBetweenVectors(vec1, vec2));

  if (angle > 90) {
    return 180 - angle;
  } else {
    return angle;
  }
}

// We don't need all the powerful features provided by Ramda#equals
export const quickVec3Comparison = (a: vec3, b: vec3): boolean => {
  return a[0] === b[0] && a[1] === b[1] && a[2] === b[2];
};

export const quickNullableVec3Comparison = (a?: vec3 | null, b?: vec3 | null): boolean => {
  if (a == null || b == null) {
    if (a == null && b == null) return true;
    return false;
  }

  return quickVec3Comparison(a, b);
};

export const quickSegmentComparison = (a: Segment, b: Segment): boolean => {
  return quickVec3Comparison(a[0], b[0]) && quickVec3Comparison(a[1], b[1]);
};

export const quickAllSegmentsComparison = (
  a: ReadonlyArray<Segment>,
  b: ReadonlyArray<Segment>
): boolean => {
  return a.length === b.length && a.every((sA, idx) => quickSegmentComparison(sA, b[idx]));
};

export const sortedAscending = (array: Array<number>): boolean => {
  return array.every(function (num, idx, arr) {
    return num <= arr[idx + 1] || idx === arr.length - 1 ? 1 : 0;
  });
};

export const sortedDescending = (array: Array<number>): boolean => {
  return array.every(function (num, idx, arr) {
    return num >= arr[idx + 1] || idx === arr.length - 1 ? 1 : 0;
  });
};

export const findSmallestTagSmid = (tags: FrameDataFragment[]): string => {
  let smallestIndex = 0;
  for (let i = 1; i < tags.length; i++) {
    if (tags[i].imageNumber < tags[smallestIndex].imageNumber) {
      smallestIndex = i;
    }
  }
  return tags[smallestIndex].smid;
};

export function getSliceParametersForImageByViewType({
  viewType,
  image,
}: {
  viewType: string;
  image: vtkImageData | null | undefined;
}): [number, number] {
  if (image == null) return [0, 0];
  const bounds = image?.getBounds();
  const extent = image?.getExtent();
  const roundBounds = (min: number, max: number) => [Math.round(min), Math.round(max)];

  if (!bounds || !extent) return [0, 0];
  switch (viewType) {
    case 'TWO_D_DRE':
      return [extent[4], extent[5]];
    case 'AXIAL_DRE':
      // @ts-expect-error [EN-7967] - TS2322 - Type 'number[]' is not assignable to type '[number, number]'.
      return roundBounds(bounds[4], bounds[5]);
    case 'CORONAL_DRE':
      // @ts-expect-error [EN-7967] - TS2322 - Type 'number[]' is not assignable to type '[number, number]'.
      return roundBounds(bounds[2], bounds[3]);
    case 'SAGITTAL_DRE':
      // @ts-expect-error [EN-7967] - TS2322 - Type 'number[]' is not assignable to type '[number, number]'.
      return roundBounds(bounds[0], bounds[1]);
    default:
      return [extent[4], extent[5]];
  }
}

// Move a given point towards the rendering camera by the provided offset distance.
export function offsetTowardsCamera(
  renderer: vtkRenderer,
  wcPoint: vec3,
  offsetDistance: number = 1.1
): ReadonlyArray<number> {
  const vcPoint = renderer.worldToView(wcPoint[0], wcPoint[1], wcPoint[2]);
  return renderer.viewToWorld(vcPoint[0], vcPoint[1], vcPoint[2] + offsetDistance);
}

export function isInsideEllipse(center: vec2, rx: number, ry: number, testPoint: vec3): boolean {
  // Calculate the normalized distance between center of ellipse to the test point such that:
  // normalizedDistance < 1 --> point inside ellipse,
  // normalizedDistance == 1 --> point on ellipse,
  // normalizedDistance > 1 --> point outside ellipse
  const normalizedDistance =
    Math.pow(testPoint[0] - center[0], 2) / (rx * rx) +
    Math.pow(testPoint[1] - center[1], 2) / (ry * ry);
  return normalizedDistance < 1;
}

export const getStencilFunction = (
  sliceNumber: number,
  [spacingX, spacingY, spacingZ]: [any, any, any],
  isInsideBounds: (gridPoint: vec3) => boolean
): ((idx: vec3) => boolean) => {
  // Define a stencil function to filter voxels that lie inside the ellipse.
  // Returns true if a voxel's center lies inside the ellipse, false otherwise.
  return function stencilFunction(idx: [number, number, number]): boolean {
    if (sliceNumber !== idx[2]) return false;
    const gridPoint: [number, number, number] = [
      idx[0] * spacingX,
      idx[1] * spacingY,
      idx[2] * spacingZ,
    ];
    return isInsideBounds(gridPoint);
  };
};

export const rotateVector = (point: vec3, angle: number, centroid: vec3): vec3 => {
  const sin = Math.sin(radiansFromDegrees(angle));
  const cos = Math.cos(radiansFromDegrees(angle));

  /**
   * A = vertex, point
   * B = triangle center, centroid
   * x' = cos * (A.x - B.x) + sin * (A.y - B.y) + B.x
   * y' = -sin * (A.x - B.x) + cos * (A.y - B.y) + B.y
   */
  const x = cos * (point[0] - centroid[0]) + sin * (point[1] - centroid[1]) + centroid[0];
  const y = -sin * (point[0] - centroid[0]) + cos * (point[1] - centroid[1]) + centroid[1];

  return [x, y, point[2]];
};

// Transform a vector from index-space to world-space
export function indexToWorldVec(
  transformer: {
    indexToWorld: (arg1: vec3) => vec3;
  },
  indexVector: vec3
): vec3 {
  // origin is always 0, 0, 0 in index space
  const indexOrigin: vec3 = [0, 0, 0];
  const worldOrigin = transformer.indexToWorld(indexOrigin);
  const worldHead = transformer.indexToWorld(indexVector);
  const worldVec: vec3 = [0, 0, 0];

  subtract(worldHead, worldOrigin, worldVec);
  return worldVec;
}

// Same as n % m but works for negative numbers too
export function mod(n: number, m: number): number {
  return ((n % m) + m) % m;
}
type TwoDPoint = [number, number];
type TwoDLine = [TwoDPoint, TwoDPoint];

export function check2DLineIntersection(
  [[line1StartX, line1StartY], [line1EndX, line1EndY]]: TwoDLine,
  [[line2StartX, line2StartY], [line2EndX, line2EndY]]: TwoDLine
): {
  x: number;
  y: number;
  onLine1: boolean;
  onLine2: boolean;
} {
  // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
  let a = 0;
  let b = 0;
  let numerator1 = 0;
  let numerator2 = 0;
  const denominator =
    (line2EndY - line2StartY) * (line1EndX - line1StartX) -
    (line2EndX - line2StartX) * (line1EndY - line1StartY);
  const result = {
    x: -1,
    y: -1,
    onLine1: false,
    onLine2: false,
  } as const;
  if (denominator === 0) {
    return result;
  }
  a = line1StartY - line2StartY;
  b = line1StartX - line2StartX;
  numerator1 = (line2EndX - line2StartX) * a - (line2EndY - line2StartY) * b;
  numerator2 = (line1EndX - line1StartX) * a - (line1EndY - line1StartY) * b;
  a = numerator1 / denominator;
  b = numerator2 / denominator;

  // if line1 is a segment and line2 is infinite, they intersect if:
  if (a > 0 && a < 1) {
    // @ts-expect-error [EN-7967] - TS2540 - Cannot assign to 'onLine1' because it is a read-only property.
    result.onLine1 = true;
  }
  // if line2 is a segment and line1 is infinite, they intersect if:
  if (b > 0 && b < 1) {
    // @ts-expect-error [EN-7967] - TS2540 - Cannot assign to 'onLine2' because it is a read-only property.
    result.onLine2 = true;
  }

  // if we cast these lines infinitely in both directions, they intersect here:
  // @ts-expect-error [EN-7967] - TS2540 - Cannot assign to 'x' because it is a read-only property.
  result.x = line1StartX + a * (line1EndX - line1StartX);
  // @ts-expect-error [EN-7967] - TS2540 - Cannot assign to 'y' because it is a read-only property.
  result.y = line1StartY + a * (line1EndY - line1StartY);

  // if line1 and line2 are segments, they intersect if both of the above are true
  return result;
}

export const rotatePointAroundPoint = (point: vec3, pivot: vec3, rads: number): vec3 => {
  const [x0, y0] = pivot;
  const [x, y] = point;
  const cosA = Math.cos(rads);
  const sinA = Math.sin(rads);
  const dx = x - x0;
  const dy = y - y0;

  return [x0 + dx * cosA - dy * sinA, y0 + dx * sinA + dy * cosA, point[2]];
};

export const vec3ToVec2 = (vec3: vec3): vec2 => {
  return [vec3[0], vec3[1]];
};

/**
 * Using the Liang-Barsky algorithm, split the line segment by the bounding box.
 * Returning the points of intersection.
 */
function clipLineByBoundingBox(start: vec3, end: vec3, bbox: number[]): Segment | null {
  const [x0, y0, z0] = start;
  const [x1, y1, z1] = end;
  const [xmin, ymin, xmax, ymax] = bbox;
  const dx = x1 - x0;
  const dy = y1 - y0;
  let t0 = 0;
  let t1 = 1;
  let p, q, r;

  for (let edge = 0; edge < 4; edge++) {
    // Traverse through left, right, bottom, top edges.
    if (edge === 0) {
      p = -dx;
      q = -(xmin - x0);
    }
    if (edge === 1) {
      p = dx;
      q = xmax - x0;
    }
    if (edge === 2) {
      p = -dy;
      q = -(ymin - y0);
    }
    if (edge === 3) {
      p = dy;
      q = ymax - y0;
    }

    if (q == null || p == null) {
      return null;
    }

    r = q / p;

    if (p === 0 && q < 0) return null; // Don't draw line at all. (parallel line outside)

    if (p < 0) {
      if (r > t1)
        return null; // Don't draw line at all.
      else if (r > t0) t0 = r; // Line is clipped!
    } else if (p > 0) {
      if (r < t0)
        return null; // Don't draw line at all.
      else if (r < t1) t1 = r; // Line is clipped!
    }
  }

  return [
    [x0 + t0 * dx, y0 + t0 * dy, z0],
    [x0 + t1 * dx, y0 + t1 * dy, z1],
  ];
}

const findFirstIntersection = (segment: Segment, regions: Region[]) => {
  for (const [idx, region] of regions.entries()) {
    const intersection = clipLineByBoundingBox(segment[0], segment[1], [...region.location]);

    if (intersection) {
      return [intersection, region, idx];
    }
  }

  return [null, null, null];
};

const sortPointsLToR = (segment: Segment): Segment => {
  const [point1, point2] = segment;

  if (point1[0] < point2[0]) {
    return [point1, point2];
  } else if (point1[0] > point2[0]) {
    return [point2, point1];
  } else {
    if (point1[1] < point2[1]) {
      return [point1, point2];
    } else if (point1[1] > point2[1]) {
      return [point2, point1];
    }

    return [point1, point2];
  }
};

const segmentLine = (fullLineSegments: Segment, subLineSegments: Segment): Segment[] => {
  const segments: Array<Segment> = [];
  const fullLine = sortPointsLToR(fullLineSegments);
  const subline = sortPointsLToR(subLineSegments);

  if (fullLine[0][0] < subline[0][0]) {
    segments.push([fullLine[0], subline[0]]);
  } else if (fullLine[0][1] < subline[0][1]) {
    segments.push([fullLine[0], subline[0]]);
  }

  if (fullLine[1][0] > subline[1][0]) {
    segments.push([subline[1], fullLine[1]]);
  } else if (fullLine[1][1] > subline[1][1]) {
    segments.push([subline[1], fullLine[1]]);
  }

  return segments;
};

/**
 * Two dimensional calculator of calibrated length using multiple regions.
 *
 * Given one line (two points), and list of regions, we can calculate the distance
 * across the regions.
 *
 * If the regions are all the same unit and there is no uncalibrated segment, we can return
 * a singular distance.
 *
 * If the regions have mixed units (s and m/s for example), then we can not combine them into a singular
 * distance value but return the two lines that would make up the edges of the triangle (as distance is the
 * hypotenuse).
 *
 * If we cross any uncalibrated space then the value is indeterminate without correction and we can only
 * return the distance across with pixels.
 *
 * The return value will be either:
 *   a singular value in calibrated units
 *   a singular value in pixels
 *   two line segments that contribute to the requested distance in mixed units
 */
export const getDistanceWithRegions = (
  segments: [Segment],
  regions: Region[],
  isMultiUnitSupported: boolean = false
): {
  distance: number;
  unit: string;
  subsegment?: Segment;
}[] => {
  const priorityOrderedRegions = regions.sort((a, b) => {
    if (a.flags.highPriority === b.flags.highPriority) {
      return 0;
    } else if (a.flags.highPriority === true && b.flags.highPriority === false) {
      return -1;
    } else {
      return 1;
    }
  });
  const pxDist = Math.sqrt(distance2BetweenPoints(segments[0][0], segments[0][1]));
  const remainingSegments = [segments[0]];

  let determinate = true;
  const currentUnit: [string | null, string | null] = [null, null];
  let currentDistance = 0;
  const distances: Array<{
    distance: number;
    subsegment?: Segment;
    unit: string;
  }> = [];
  // Create a stack of segments as we process multiple regions, overlapping regions can still be calculated
  while (remainingSegments.length > 0) {
    const nextSegment = remainingSegments.pop();
    const [intersection, region, idx] = findFirstIntersection(nextSegment, priorityOrderedRegions);

    // If we do not find an intersection or region, we are in uncalibrated space and
    // we can only display the pixel measurement value.
    if (intersection == null || region == null) {
      determinate = false;
      break;
    }

    if (idx != null) {
      // Region is counted, remove
      // This prevents an infinite loop where the intersection might be a single point
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      priorityOrderedRegions.splice(idx, 1);
    }

    // Find and update the current measured units based on region,
    // if they end up differing as we resegment the line we fall into unmeasureable
    // space and can only measure pixels
    if (
      // @ts-expect-error [EN-7967] - TS2339 - Property 'physicalUnits' does not exist on type 'number | Region | Segment'.
      (currentUnit[0] != null && currentUnit[0] !== region.physicalUnits[0]) ||
      // @ts-expect-error [EN-7967] - TS2339 - Property 'physicalUnits' does not exist on type 'number | Region | Segment'.
      (currentUnit[1] != null && currentUnit[1] !== region.physicalUnits[1])
    ) {
      determinate = false;
      break;
    }

    // Grab and set the physical units if not already set for both directions
    if (currentUnit[0] == null) {
      // @ts-expect-error [EN-7967] - TS2339 - Property 'physicalUnits' does not exist on type 'number | Region | Segment'.
      currentUnit[0] = region.physicalUnits[0];
    }

    if (currentUnit[1] == null) {
      // @ts-expect-error [EN-7967] - TS2339 - Property 'physicalUnits' does not exist on type 'number | Region | Segment'.
      currentUnit[1] = region.physicalUnits[1];
    }

    // TODO(VX-702): based on the spec, this might not be as described, it might still only use 1 single regions
    // dimensions. As we are currently only targeting tissue this is a lower incident usage.
    // If we have the same units across regions, we can sum and calculate distance
    if (currentUnit[0] === currentUnit[1]) {
      // @ts-expect-error [EN-7967] - TS2339 - Property 'map' does not exist on type 'number | Region | Segment'.
      const scaled = intersection.map((point) => {
        const [x, y, z] = point;

        // @ts-expect-error [EN-7967] - TS2339 - Property 'physicalDelta' does not exist on type 'number | Region | Segment'. | TS2339 - Property 'physicalDelta' does not exist on type 'number | Region | Segment'.
        return [x * region.physicalDelta[0], y * region.physicalDelta[1], z];
      });

      currentDistance += Math.sqrt(distance2BetweenPoints(scaled[0], scaled[1]));
    } else if (currentUnit[0] != null && currentUnit[1] != null) {
      // If our units differ in direction we can not calculate one single distance and line
      // but need to display a vertical and horizontal line. Useful for seconds vs unit/second.
      const unitX = currentUnit[0];
      const unitY = currentUnit[1];
      const scaledY = [
        [intersection[0][0], intersection[0][1], 0],
        [intersection[0][0], intersection[1][1], 0],
      ].map((line) => {
        const [x, y, z] = line;

        // @ts-expect-error [EN-7967] - TS2339 - Property 'physicalDelta' does not exist on type 'number | Region | Segment'. | TS2339 - Property 'physicalDelta' does not exist on type 'number | Region | Segment'.
        return [x * region.physicalDelta[0], y * region.physicalDelta[1], z];
      });
      const scaledX = [
        [intersection[0][0], intersection[1][1], 0],
        [intersection[1][0], intersection[1][1], 0],
      ].map((line) => {
        const [x, y, z] = line;

        // @ts-expect-error [EN-7967] - TS2339 - Property 'physicalDelta' does not exist on type 'number | Region | Segment'. | TS2339 - Property 'physicalDelta' does not exist on type 'number | Region | Segment'.
        return [x * region.physicalDelta[0], y * region.physicalDelta[1], z];
      });

      distances.push({
        // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'any[]' is not assignable to parameter of type 'Vector3'.
        distance: Math.sqrt(distance2BetweenPoints(scaledY[0], scaledY[1])),
        unit: unitY,
        subsegment: [
          [intersection[0][0], intersection[0][1], 0],
          [intersection[0][0], intersection[1][1], 0],
        ],
      });
      distances.push({
        // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'any[]' is not assignable to parameter of type 'Vector3'.
        distance: Math.sqrt(distance2BetweenPoints(scaledX[0], scaledX[1])),
        unit: unitX,
        subsegment: [
          [intersection[0][0], intersection[1][1], 0],
          [intersection[1][0], intersection[1][1], 0],
        ],
      });
    }

    // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'number | Region | Segment' is not assignable to parameter of type 'Segment'.
    remainingSegments.push(...segmentLine(nextSegment, intersection));
  }

  // If we have determined we can not calculate distance, return pixels
  if (!determinate || currentUnit[0] == null || (distances.length === 2 && !isMultiUnitSupported)) {
    return [{ distance: pxDist, unit: 'px' }];
  }

  // Support mixed units with two lines
  if (distances.length === 2) {
    //we have subsegments;
    return distances;
  }

  // Our annotations only currently handle implicit `mm` within schema
  // If we have cm - convert it to mm.
  if (currentUnit[0] === 'cm') {
    return [{ distance: currentDistance * 10, unit: 'mm' }];
  }

  return [{ distance: currentDistance, unit: currentUnit[0] }];
};

export type Bounds = [number, number, number, number, number, number];

export const getBounds = (segments: vec3[]): Bounds => {
  // Calculate world-bounds to scan for eligible voxels.
  // This will reduce the number of voxels that are fetched to calculate the stats.
  const scanBounds = [
    Number.POSITIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
    Number.POSITIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
    Number.POSITIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
  ];

  // The bounds are stored as six-valued array as follows: [x_min, x_max, y_min, y_max, z_min, z_max].
  // The following loop ensures the correct ordering of values: x_min < x_max, y_min < y_max, z_min < z_max
  segments.forEach(([x, y, z]: [any, any, any]) => {
    if (x <= scanBounds[0]) {
      scanBounds[0] = x;
    }
    if (x >= scanBounds[1]) {
      scanBounds[1] = x;
    }

    if (y <= scanBounds[2]) {
      scanBounds[2] = y;
    }
    if (y >= scanBounds[3]) {
      scanBounds[3] = y;
    }

    if (z <= scanBounds[4]) {
      scanBounds[4] = z;
    }
    if (z >= scanBounds[5]) {
      scanBounds[5] = z;
    }
  });

  // @ts-expect-error [EN-7967] - TS2322 - Type 'number[]' is not assignable to type 'Bounds'.
  return scanBounds;
};

export type TransformMatrix = NonNullable<ImageRegistration['transformMatrix']>;

export function applyTransformMatrix(position: vec3, transform: TransformMatrix): vec3 {
  // Convert to 4d for transform. The extra 1 allows for more translational movement
  const positionVec = [...position, 1];

  const resultVec = [
    transform[0][0] * positionVec[0] +
      transform[0][1] * positionVec[1] +
      transform[0][2] * positionVec[2] +
      transform[0][3] * positionVec[3],
    transform[1][0] * positionVec[0] +
      transform[1][1] * positionVec[1] +
      transform[1][2] * positionVec[2] +
      transform[1][3] * positionVec[3],
    transform[2][0] * positionVec[0] +
      transform[2][1] * positionVec[1] +
      transform[2][2] * positionVec[2] +
      transform[2][3] * positionVec[3],
    transform[3][0] * positionVec[0] +
      transform[3][1] * positionVec[1] +
      transform[3][2] * positionVec[2] +
      transform[3][3] * positionVec[3],
  ];

  // remove fourth dimension to return to 3d
  // resultVec[3] will always be 1 and has no "information" in it
  return [resultVec[0], resultVec[1], resultVec[2]];
}

/**
 * [ux vx wx tx]
 * [uy vy wy ty]
 * [uz vz wz tz]
 * [ 0  0  0  1]
 * inverts to
 * [ux uy uz -ux*tx-uy*ty-uz*tz]
 * [vx vy vz -vx*tx-vy*ty-vz*tz]
 * [wx wy wz -wx*tx-wy*ty-wz*tz]
 * [ 0  0  0          1        ]
 */
export function invertTransformMatrix(t: TransformMatrix): TransformMatrix {
  return [
    [t[0][0], t[1][0], t[2][0], -t[0][0] * t[0][3] - t[1][0] * t[1][3] - t[2][0] * t[2][3]],
    [t[0][1], t[1][1], t[2][1], -t[0][1] * t[0][3] - t[1][1] * t[1][3] - t[2][1] * t[2][3]],
    [t[0][2], t[1][2], t[2][2], -t[0][2] * t[0][3] - t[1][2] * t[1][3] - t[2][2] * t[2][3]],
    [0, 0, 0, 1],
  ];
}

/**
 * Utilizing the ROI stats we scale the known elements by the calculated SUV Scale Factor
 *
 * Returns the scaled stats
 */
export const getSUVROI = (
  stencilFunction: (point: vec3) => boolean,
  bounds: Bounds,
  image: vtkImageData | null | undefined,
  suvScaleFactor: number
): {
  minimum: number;
  maximum: number;
  mean: number;
  stdDev: number;
  count: number;
  variance?: number;
} => {
  const roi = getROI(stencilFunction, bounds, image);

  roi.minimum = roi.minimum * suvScaleFactor;
  roi.maximum = roi.maximum * suvScaleFactor;
  roi.mean = roi.mean * suvScaleFactor;
  roi.stdDev = roi.stdDev * Math.abs(suvScaleFactor);

  return roi;
};

/**
 * For given bounds, and a stencil function, calulates stats for the the image.
 *
 * Returns stats for the pixels within the bounds in the image
 *
 * This will be preferred most of the time only on PET with the calculatable scale factor will it
 * use the SUV ROI util.
 */
export const getROI = (
  stencilFunction: (point: vec3) => boolean,
  bounds: Bounds,
  image?: vtkImageData | null
): {
  minimum: number;
  maximum: number;
  mean: number;
  stdDev: number;
  count: number;
  variance?: number;
} => {
  const emptyStats = {
    minimum: 0,
    maximum: 0,
    sigma: 0,
    average: 0,
    count: 0,
  } as const;

  // Compute required metrics using the filter function.
  const { average, sigma, ...restStats } =
    image && image.getPointData().getScalars()
      ? image.computeHistogram(bounds, stencilFunction)
      : emptyStats;

  const mean = Math.round(average);
  const stdDev = Math.round(sigma);

  // @ts-expect-error [EN-7967] - TS2322 - Type '{ mean: number; stdDev: number; minimum: 0; maximum: 0; count: 0; } | { mean: number; stdDev: number; minimum: number; maximum: number; variance: number; }' is not assignable to type '{ minimum: number; maximum: number; mean: number; stdDev: number; count: number; variance?: number; }'.
  return {
    ...restStats,
    mean,
    stdDev,
  };
};

/**
 * Utility to determine if we can calculate the SUV scale factor given the tags for PET image

 * @returns {Boolean} whether we can calculate the SUV factor
 */
export function canCalculateScaleFactor(tags?: ScaleFactorTags | null): boolean {
  if (tags == null) {
    return false;
  }
  const petTags = tags.modules.petImage;
  return (
    petTags?.preCalculatedSuvBwFactor != null ||
    (petTags != null &&
      CORRECTED_IMAGE_TAGS.every((tag) => petTags.correctedImage.includes(tag)) &&
      petTags.decayCorrection === 'START')
  );
}

/**
 * Given tags and study parameters calculate the Body weight version of the
 * scale factor for SUV with PET details
 *
 * @returns {number} The factor to scale the PET pixel values by
 */
export function calculateBwScaleFactor(tags?: ScaleFactorTags | null): number {
  if (tags == null) {
    return NaN;
  }
  const petTags = tags.modules.petImage;
  const patientStudy = tags.modules.patientStudy;

  if (petTags == null) {
    throw new Error('Can not calculate scale factor with no tags module');
  }

  if (petTags.preCalculatedSuvBwFactor != null) {
    return petTags.preCalculatedSuvBwFactor;
  }

  if (petTags.units === PET_UNITS.BQML) {
    const decayTime =
      (new Date(petTags.scanDateTime).valueOf() - new Date(petTags.startDateTime).valueOf()) / 1000;
    const injectedDose = petTags.radiopharmaceuticalInformation.radiopharmaceuticalTotalDose ?? 0;
    const decayDose =
      injectedDose *
      Math.pow(2, -decayTime / (petTags.radiopharmaceuticalInformation.radionuclideHalfLife ?? 1));
    const weight = patientStudy?.patientWeight ?? 0;
    return (weight * 1000) / decayDose;
  } else if (petTags.units === PET_UNITS.CNTS && petTags.suvScaleFactor != null) {
    return petTags.suvScaleFactor; // Phillips private
  } else if (petTags.units === PET_UNITS.GML) {
    return 1;
  }

  throw new Error(`Unknown units: ${petTags.units}`);
}

/**
 * Given pixel values, and the study with tags for PET image, map the pixel values
 * to their SUV values for each pixel.
 *
 * From here, we can use the result to calulcate max, min, mean, etc.
 *
 * It can work on an array of numbers (similar to compute histogram) or a single pixel value
 *
 * No rounding is made on the numbers, any display format will be handled elsewhere
 *
 * @returns {number|number[]|null} Returns the scaled numbers, null if not able to calculate given params
 */
// @ts-expect-error [EN-7967] - TS2322 - Type '(pixelValues: any, tags: any) => number | number[]' is not assignable to type '((pixelValues: number, tags?: ScaleFactorTags) => number) & ((pixelValues: number[], tags?: ScaleFactorTags) => number[])'.
export const calculateBwSUV: ((
  pixelValues: number,
  tags?: ScaleFactorTags | null | undefined
) => number | null) &
  ((pixelValues: number[], tags?: ScaleFactorTags | null | undefined) => number[] | null) = (
  pixelValues,
  tags
) => {
  if (tags == null || !canCalculateScaleFactor(tags)) {
    return null;
  }
  if (Array.isArray(pixelValues)) {
    const factor = calculateBwScaleFactor(tags);
    return pixelValues.map((v) => v * factor);
  } else {
    return pixelValues * calculateBwScaleFactor(tags);
  }
};

export const getOppositeCorners = (
  topLeft: vec3,
  bottomRight: vec3,
  {
    rotation,
    pivot,
  }: {
    pivot: vec3;
    rotation: number;
  }
): [vec3, vec3] => {
  // these calculate the corners for the bounds of the ellipse
  // for gsps, we dont need to rotate our corners because we rotate the entire rectangle afterwards
  // for non-gsps, the user has anchored the top left and bottom right points of the ellipse with their mouse
  // and this calculate the corresponding corners with respect to that positon and the rotation value
  const topLeftRotated = rotatePointAroundPoint(topLeft, pivot, -2 * rotation); // top left rotated point
  const bottomRightRotated = rotatePointAroundPoint(bottomRight, pivot, -2 * rotation); // bottom right rotated point

  // Create an X-Y bounding box around the rotated points
  return [
    [bottomRightRotated[0], topLeftRotated[1], topLeft[2]],
    [topLeftRotated[0], bottomRightRotated[1], bottomRight[2]],
  ];
};

function isLeft(p: vec3, x: vec3, y: vec3): number {
  return (y[0] - x[0]) * (p[1] - x[1]) - (p[0] - x[0]) * (y[1] - x[1]);
}

export const isPointInPolygon = (p: vec3 | null | undefined, lines: Segment[]): boolean => {
  let windingNumber = 0;

  if (p == null) {
    return false;
  }

  lines.forEach((line, i) => {
    const [a, b] = line;
    if (a[1] <= p[1]) {
      if (b[1] > p[1] && isLeft(p, a, b) > 0) {
        windingNumber += 1;
      }
    } else if (b[1] <= p[1] && isLeft(p, a, b) < 0) {
      windingNumber -= 1;
    }
  });

  return windingNumber !== 0;
};
