import {
  RAW_ARRAY_TYPES,
  TypedArrayShortName,
  castBufferToSupportedTextureTypedArray,
  castToTypedArrayShortName,
} from 'utils/typedArray';
import type { HTJ2KDecoderType } from '@cornerstonejs/codec-openjph';
import type { SupportedTextureTypes, SupportedTexturesMap } from 'utils/textureUtils';
import type { TypedArrayShortNameType } from 'utils/typedArray';
import { processModalityLut } from 'utils/dicom';
import type { OrderedFrame } from './BaseImageLoader';

/**
 * Decompresses a GZipped compressed buffer.
 */
export async function decompressGzippedBuffer(buffer: ArrayBuffer): Promise<ArrayBuffer> {
  // TODO - Will likely need to use something such as https://github.com/ColinTimBarndt/wasm-gzip for
  // Safari and any other browsers that do not support DecompressionStream.
  const ds = new DecompressionStream('gzip');

  const writer = ds.writable.getWriter();
  writer.write(buffer);
  writer.close();
  return new Response(ds.readable).arrayBuffer();
}

/**
 * Decompresses a High Throughput JPEG2000 (HTJ2K) compressed buffer and returns a TypedArray compatible with WebGL.
 */
export async function decompressJpegBuffer(
  encodedBitStream: Uint8Array,
  dataKind: string,
  openJPHModuleLoaderPromise: Promise<{
    HTJ2KDecoder: HTJ2KDecoderType;
  }>,
  SUPPORTED_TEXTURES: SupportedTexturesMap
): Promise<SupportedTextureTypes> {
  const OpenJPH = await openJPHModuleLoaderPromise;
  const decoder = new OpenJPH.HTJ2KDecoder();

  const encodedBuffer = decoder.getEncodedBuffer(encodedBitStream.length);

  encodedBuffer.set(encodedBitStream);
  decoder.readHeader();
  decoder.decodeSubResolution(0);

  const decodedBuffer: Uint8Array = decoder.getDecodedBuffer();
  let supportedTypedArray = null;

  switch (dataKind) {
    case TypedArrayShortName.Int8Array:
    case TypedArrayShortName.RawInt8Array: {
      const data = new Int8Array(
        decodedBuffer.buffer,
        decodedBuffer.byteOffset,
        decodedBuffer.byteLength
      );
      if (SUPPORTED_TEXTURES.snorm) {
        // Make a copy, we are going to delete the decodedBuffer
        supportedTypedArray = Int8Array.from(data);
      } else if (SUPPORTED_TEXTURES.norm16) {
        supportedTypedArray = Int16Array.from(data);
      } else {
        supportedTypedArray = Float32Array.from(data);
      }

      break;
    }
    case TypedArrayShortName.Uint8Array:
    case TypedArrayShortName.RawUint8Array: {
      const data = new Uint8Array(
        decodedBuffer.buffer,
        decodedBuffer.byteOffset,
        decodedBuffer.byteLength
      );
      if (SUPPORTED_TEXTURES.snorm) {
        // Make a copy, we are going to delete the decodedBuffer
        supportedTypedArray = Uint8Array.from(data);
      } else if (SUPPORTED_TEXTURES.norm16) {
        supportedTypedArray = Uint16Array.from(data);
      } else {
        supportedTypedArray = Float32Array.from(data);
      }

      break;
    }
    case TypedArrayShortName.Int16Array:
    case TypedArrayShortName.RawInt16Array: {
      const data = new Int16Array(
        decodedBuffer.buffer,
        decodedBuffer.byteOffset,
        decodedBuffer.byteLength / 2
      );

      if (SUPPORTED_TEXTURES.norm16) {
        // Make a copy, we are going to delete the decodedBuffer
        supportedTypedArray = Int16Array.from(data);
      } else {
        supportedTypedArray = Float32Array.from(data);
      }

      break;
    }

    case TypedArrayShortName.Uint16Array:
    case TypedArrayShortName.RawUint16Array: {
      const data = new Uint16Array(
        decodedBuffer.buffer,
        decodedBuffer.byteOffset,
        decodedBuffer.byteLength / 2
      );
      if (SUPPORTED_TEXTURES.norm16) {
        // Make a copy, we are going to delete the decodedBuffer
        supportedTypedArray = Uint16Array.from(data);
      } else {
        supportedTypedArray = Float32Array.from(data);
      }

      break;
    }

    default:
      throw new Error(`Unhandled data type encountered: ${dataKind}`);
  }

  // @ts-expect-error [EN-7967] - TS2339 - Property 'delete' does not exist on type 'HTJ2KDecoder'.
  decoder.delete();

  return supportedTypedArray;
}

export function isRawDataType(dataKind: TypedArrayShortNameType): boolean {
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'TypedArrayShortNameType' is not assignable to parameter of type '"F4" | "F8" | "U1" | "I1" | "U2" | "I2" | "U4" | "I4"'.
  return RAW_ARRAY_TYPES.includes(dataKind);
}

function getHeaderData(dataBuffer: ArrayBuffer) {
  let readOffset = 0;

  // Next 3 bytes are a header containing data type, item size, and compression algorithm
  const dataKindBuffer = dataBuffer.slice(readOffset, (readOffset += 2));
  const compressionAlgorithmBuffer = dataBuffer.slice(readOffset, (readOffset += 1));

  const dataKind = castToTypedArrayShortName(new TextDecoder('utf-8').decode(dataKindBuffer));
  const compressionAlgorithm = new TextDecoder('utf-8').decode(compressionAlgorithmBuffer);

  return {
    dataKind,
    compressionKind: compressionAlgorithm,
    readOffset,
  };
}

async function processCompression(
  dataBuffer: ArrayBuffer,
  openJPHModuleLoaderPromise: Promise<{
    HTJ2KDecoder: HTJ2KDecoderType;
  }>,
  SUPPORTED_TEXTURES: SupportedTexturesMap,
  {
    dataKind,
    compressionKind,
    readOffset,
  }: {
    dataKind: TypedArrayShortNameType;
    compressionKind: string;
    readOffset: number;
  }
) {
  let pixelDataBuffer;

  switch (compressionKind) {
    case 'g':
      pixelDataBuffer = await decompressGzippedBuffer(dataBuffer.slice(readOffset));
      return castBufferToSupportedTextureTypedArray(dataKind, pixelDataBuffer, SUPPORTED_TEXTURES);
    case 'j':
      // Remaining buffer is the contents of the file, potentially compressed
      // Using an Uint8 view into existing dataBuffer with offset
      const dataBufferView = new Uint8Array(dataBuffer, readOffset);

      return await decompressJpegBuffer(
        dataBufferView,
        dataKind,
        openJPHModuleLoaderPromise,
        SUPPORTED_TEXTURES
      );
    default:
      return castBufferToSupportedTextureTypedArray(
        dataKind,
        dataBuffer.slice(readOffset),
        SUPPORTED_TEXTURES
      );
  }
}

export async function parsePixelData(
  dataBuffer: ArrayBuffer,
  openJPHModuleLoaderPromise: Promise<{
    HTJ2KDecoder: HTJ2KDecoderType;
  }>,
  SUPPORTED_TEXTURES: SupportedTexturesMap,
  frame?: OrderedFrame | null
): Promise<SupportedTextureTypes> {
  const header = getHeaderData(dataBuffer);

  const decompressedData = await processCompression(
    dataBuffer,
    openJPHModuleLoaderPromise,
    SUPPORTED_TEXTURES,
    header
  );

  if (frame != null && isRawDataType(header.dataKind)) {
    return processModalityLut(frame, decompressedData);
  }

  return decompressedData;
}
