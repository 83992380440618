import { BroadcastChannel } from 'broadcast-channel';
import { systemCheckPreferenceState } from 'common/SystemCheck/useSystemCheck';
import { extractWorklistIds } from 'hooks/useWorklistId';
import { uniquePageId as id } from 'modules/activeWindow';
import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getPageType, PAGE_TYPES } from 'utils/pageTypes';
import { PATH } from '../config/constants';
import type { PageTypes } from '../utils/pageTypes';
import { getPathName } from '../utils/pageTypes';
import { extensionAppState, ExtWindowName } from './useExtensionState';

export const getWindowId = (urlOrPathname: string): string | null | undefined => {
  const pathname = getPathName(urlOrPathname);
  const viewerMatch = matchPath(PATH.VIEWER, pathname);
  return viewerMatch?.params?.windowId;
};

export type PageType = {
  type: (typeof PAGE_TYPES)[keyof typeof PAGE_TYPES];
  id: string;
  createTimestamp: number;
  timestamp: number;
  windowId: string | null | undefined;
  url: string;
  worklistIds: Array<string>;
};

type Tab = {
  type: (typeof PAGE_TYPES)[keyof typeof PAGE_TYPES];
  windowId: string | null | undefined;
  url: string;
  worklistIds: Array<string>;
  path: string;
};

// Constant time for when the page was originally created, even when
// refreshing the timestamp to maintain active tab states it will have a
// property to allow sorting by when they were created.
const creationTimestamp = Date.now();

export const getPage = (): PageType => ({
  type: getPageType(window.location.href),
  id,
  createTimestamp: creationTimestamp,
  timestamp: Date.now(),
  windowId: getWindowId(window.location.href),
  url: window.location.href,
  worklistIds: extractWorklistIds(window.location.pathname),
});

export type Message =
  | {
      type: 'register' | 'unregister';
      payload: PageType;
    }
  | {
      type: 'ping';
    };
export const bc: BroadcastChannel<Message> = new BroadcastChannel<Message>('use-tab-list');

const register = () => bc.postMessage({ type: 'register', payload: getPage() });
register();

window.addEventListener('beforeunload', () => {
  bc.postMessage({ type: 'unregister', payload: getPage() });
});

const WINDOW_NAME_TO_ID = {
  viewer0: '0',
  viewer1: '1',
  reporter: undefined,
  worklist: undefined,
  'patient-jacket': undefined,
} as const;

/**
 * Creates an artificial `Tab` object to inject into the hook when the extension is not detected.
 * The source of truth for tabs should come from the extension; this should only be used as a last
 * resort fallback for system check bypasses and other edge cases like supporting the Vision Pro.
 */
const createArtificialTab = (type: (typeof PAGE_TYPES)[keyof typeof PAGE_TYPES]): Tab => ({
  type,
  windowId: type === PAGE_TYPES.VIEWER ? '0' : '1',
  url: window.location.href,
  path: window.location.pathname,
  worklistIds: extractWorklistIds(window.location.pathname),
});

export const useOpenTabs = (): Array<Tab> => {
  const extensionState = useRecoilValue(extensionAppState);
  const systemCheckPreference = useRecoilValue(systemCheckPreferenceState);

  const tabs: Array<Tab> = useMemo(() => {
    const tabList = [];

    if (Object.entries(extensionState?.windows ?? {}).length === 0) {
      if (systemCheckPreference?.shouldBypassSystemCheck) {
        // At this point the extension has not been detected and the system check has been bypassed,
        // so we should return an artificial tab so that the downstream logic will allow the viewer
        // to render something.

        // Identify the window
        const type = window.location.pathname.includes('viewer')
          ? PAGE_TYPES.VIEWER
          : PAGE_TYPES.WORKLIST;

        return [createArtificialTab(type)];
      }
      return [];
    }

    Object.entries(extensionState.windows).forEach(([windowName, tab]: [any, any]) => {
      if (tab == null) return;

      let windowId: string | null | undefined = undefined;
      windowId = WINDOW_NAME_TO_ID[windowName];

      let type: PageTypes = PAGE_TYPES.WORKLIST;
      if (windowName === ExtWindowName.Reporter) {
        type = PAGE_TYPES.REPORTER;
      } else if (windowName === ExtWindowName.Patient_Jacket) {
        type = PAGE_TYPES.PATIENT_JACKET;
      } else if (windowName === ExtWindowName.Viewer0 || windowName === ExtWindowName.Viewer1) {
        type = PAGE_TYPES.VIEWER;
      }

      tabList.push({
        type,
        windowId,
        url: tab.url,
        path: tab.path,
        worklistIds: extractWorklistIds(tab.path),
      });
    });

    return tabList.filter(Boolean).sort((a, b) => {
      if (a.windowId != null && b.windowId != null) return a.windowId.localeCompare(b.windowId);
      return -1;
    });
  }, [extensionState.windows, systemCheckPreference?.shouldBypassSystemCheck]);

  return tabs;
};

export const crossTabBroadcastChannel: BroadcastChannel<
  | {
      type: 'request' | 'response';
      url: string;
      id?: string;
    }
  | {
      type: 'setSize';
      tabId: string;
      width?: number;
      height?: number;
      top?: number;
      left?: number;
    }
> = new BroadcastChannel('cross-tab-controls');

const TIME_TO_LOAD =
  window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;

// Highest value between 3s and the (time it took to load the current page + 1s)
const CHECK_TIMEOUT = Math.max(3000, TIME_TO_LOAD + 1000);

/**
 * The below behemoth is required to detect if a popup has been blocked
 * by an ad blocker so that we can inform the user about it.
 *
 * The system is actually quite simple, every page listens to a specific
 * broadcast channel event, when the event is received, the page will
 * check if the event is looking for that specific page, and if it is,
 * it will send a message back to the page that requested the check.
 */
export const checkTabPresence = (url: string): Promise<string | null | undefined> => {
  return new Promise(
    async (
      resolve: (result?: Promise<string | null | undefined> | string | null | undefined) => void
    ) => {
      let aborted = false;

      // This will fire after 1 second if no `response` event is received
      const timeout = setTimeout(() => {
        aborted = true;
        resolve(null);
      }, CHECK_TIMEOUT);

      // If a `response` event is received and the URL matches, we can
      // assume that the tab is open and we can resolve the promise
      // with the tab ID
      const callback = async (
        event:
          | {
              id?: string;
              type: 'request' | 'response';
              url: string;
            }
          | {
              height?: number;
              left?: number;
              tabId: string;
              top?: number;
              type: 'setSize';
              width?: number;
            }
      ) => {
        if (event.type === 'response' && event.url === url) {
          aborted = true;

          // If we receive a response we cancel the above timer and resolve the promise positively
          clearTimeout(timeout);
          resolve(event.id);
        }
      };
      crossTabBroadcastChannel.addEventListener('message', callback);

      // We send a request to the broadcast channel, this will trigger
      // the callback above if a tab is open with the same URL
      // We retry every 100ms until we either receive a response or
      // the timeout is reached
      while (aborted === false) {
        crossTabBroadcastChannel.postMessage({ type: 'request', url, id });
        await new Promise((resolve: (result: Promise<undefined> | undefined) => void) =>
          setTimeout(resolve, 100)
        );
      }

      // We remove the callback from the broadcast channel
      crossTabBroadcastChannel.removeEventListener('message', callback);
    }
  );
};

/**
 * The following event listener is used to make the `checkTabPresence`
 * and `setTabSize` work.
 */
crossTabBroadcastChannel.addEventListener('message', (event) => {
  switch (event.type) {
    case 'request': {
      if (event.url === window.location.pathname) {
        crossTabBroadcastChannel.postMessage({
          type: 'response',
          url: event.url,
          id,
        });
      }
      break;
    }
    case 'setSize': {
      if (event.tabId === id) {
        if (event.width != null && event.height != null) {
          // This is the height of the tabs bar + address bar + bookmarks bar
          const browserInterfaceHeight = window.outerHeight - window.innerHeight;
          window.resizeTo(event.width, event.height + browserInterfaceHeight);
        }
        if (event.top != null && event.left != null) {
          window.moveTo(event.left, event.top);
        }
      }
      break;
    }
    default:
  }
});
