import { generatePath, matchPath } from 'react-router-dom';
import { AUTHLESS_PATHS, PATH } from 'config/constants';

export const isAuthlessPathname = (pathname?: string | null): boolean =>
  pathname != null ? AUTHLESS_PATHS.some((path) => matchPath(path, pathname)) : false;

export const makeViewerUrl = (smid: string, windowId: number): string =>
  // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type 'string'.
  generatePath(PATH.VIEWER, { windowId: windowId, worklistId: smid });

export const makeReporterUrl = (smid: string): string =>
  generatePath(PATH.REPORTER, { worklistId: smid });
