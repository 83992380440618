import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';

import { pathToRegexp } from 'path-to-regexp';

import { PATH, NOT_FOUND_CASE_ID } from 'config/constants';

export const extractWorklistIds = (path: string): string[] => {
  const viewerKeys: Array<any> = [];
  const viewerRegexp = pathToRegexp(PATH.VIEWER, viewerKeys);
  const viewerMatch = viewerRegexp.exec(path);

  const viewerId = viewerMatch?.[viewerKeys.findIndex((k) => k.name === 'worklistId') + 1];

  const reporterKeys: Array<any> = [];
  const reporterRegexp = pathToRegexp(PATH.REPORTER, reporterKeys, { end: false });
  const reporterMatch = reporterRegexp.exec(path);
  const reporterId = reporterMatch?.[reporterKeys.findIndex((k) => k.name === 'worklistId') + 1];
  const id = viewerId ?? reporterId;
  return id != null && id !== NOT_FOUND_CASE_ID ? [id] : [];
};

const useWorklistId = (): string | null | undefined => {
  const matchViewer = useMatch(PATH.VIEWER);
  const matchReporter = useMatch(PATH.REPORTER);
  const matchURT = useMatch(PATH.URT);
  const matchPatientJacket = useMatch(PATH.PATIENT_JACKET);
  const matchUndockedPatientJacket = useMatch(PATH.PATIENT_JACKET_UNDOCKED);
  const worklistId =
    matchViewer?.params.worklistId ??
    matchReporter?.params.worklistId ??
    matchURT?.params.worklistId ??
    matchPatientJacket?.params.smid ??
    matchUndockedPatientJacket?.params.smid ??
    '';

  const smid = useMemo(() => {
    return worklistId.length > 0 && worklistId !== 'undefined' ? worklistId : null;
  }, [worklistId]);

  return smid;
};

export default useWorklistId;
